import React from 'react'

import PrivateRoutes from './PrivateRoute/private-routes'

const TextEditor = React.lazy(() => import('./TextEditor/text-editor'))
const DeleteModal = React.lazy(() => import('./DeleteModal/delete-modal'))
const SubmitButton = React.lazy(() => import('./SubmitButton/submit-button'))
const Pagination = React.lazy(() => import('./Pagination/pagination'))
const DonationModal = React.lazy(() => import('./DonationModal/donation-modal'))

export { TextEditor, DeleteModal, SubmitButton, Pagination, PrivateRoutes }
