import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isInvalidToken: true,
  isAuthenticated: false,
  user: {},
  token: '',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticate(state, action) {
      state.isAuthenticated = true
      state.user = action.payload.user
      state.token = action.payload.token
      state.isInvalidToken = false
    },
    logout(state) {
      state.isInvalidToken = true
      state.isAuthenticated = false
      state.user = null
      state.token = null
    },
    updateUser(state, action) {
      state.user = action.payload
      return state
    },
  },
})

export const { authenticate, logout, updateUser } = authSlice.actions
export default authSlice.reducer
