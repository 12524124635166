import { isRejectedWithValue } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    console.warn('We got a rejected action!')

    // check if there's any validation error of post requests
    if (action?.meta?.arg?.type === 'mutation') {
      let validationErrors = Object.values(action?.payload?.data?.errors || {})

      if (validationErrors.length > 0) {
        // get first error
        let error = validationErrors[0][0] || null
        toast.error(error)
      } else {
        // show the response message
        toast.error(action?.payload?.data?.message)
      }
    }
  }

  return next(action)
}
