// frontend routes
export const LOGIN_ROUTE = '/login'
export const HOSPITAL_LIST_ROUTE = '/hospitals'
export const DOCTORS_LIST_ROUTE = '/doctors'
export const SPECIALTY_LIST_ROUTE = '/specialties'
export const SLIDER_LIST_ROUTE = '/hero-sliders'
export const NEWS_LIST_ROUTE = '/news-list'
export const DONOR_LIST_ROUTE = '/donor-list'

// api routes
export const ADMIN_HOSPITAL_LIST = '/admin/hospitals'
export const ADMIN_ADD_HOSPITAL = '/admin/hospitals'
export const ADMIN_GET_HOSPITAL = '/admin/hospitals'
export const ADMIN_UPDATE_HOSPITAL = '/admin/hospitals'
export const ADMIN_REMOVE_HOSPITAL = '/admin/hospitals'
export const ADMIN_SEARCH_HOSPITAL = '/admin/hospitals/search'

export const ADMIN_NEWSLETTERS_LIST = '/admin/newsletters'
export const ADMIN_REMOVE_NEWSLETTER = '/admin/newsletters'
export const ADMIN_LIST_HERO_SLIDERS = '/admin/heroes'
export const ADMIN_ADD_HERO_SLIDER = '/admin/heroes'
export const ADMIN_GET_HERO_SLIDER = '/admin/heroes'
export const ADMIN_UPDATE_HERO_SLIDER = '/admin/heroes'
export const ADMIN_DELETE_HERO_SLIDER = '/admin/heroes'

export const ADMIN_DOCTOR_LIST = '/admin/doctors'
export const ADMIN_ADD_DOCTOR = '/admin/doctors'
export const ADMIN_GET_DOCTOR = '/admin/doctors'
export const ADMIN_UPDATE_DOCTOR = '/admin/doctors'
export const ADMIN_REMOVE_DOCTOR = '/admin/doctors'
export const ADMIN_SEARCH_DOCTOR = '/admin/doctors/search'

export const SPECIALTY_LIST = 'admin/specialties'
export const ADD_SPECIALTY = 'admin/specialties'
export const GET_SPECIALTY = 'admin/specialties'
export const UPDATE_SPECIALTY = 'admin/specialties'
export const REMOVE_SPECIALTY = 'admin/specialties'
export const REORDER_SPECIALTY = 'admin/specialties/reorder'

export const ADMIN_LIST_NEWS = '/admin/news'
export const ADMIN_ADD_NEWS = '/admin/news'
export const ADMIN_GET_NEWS = '/admin/news'
export const ADMIN_UPDATE_NEWS = '/admin/news'
export const ADMIN_DELETE_NEWS = '/admin/news'

export const ADMIN_DONOR_LIST = '/admin/donors'
export const ADMIN_ADD_DONOR = '/admin/donors'
export const ADMIN_GET_DONOR = '/admin/donors'
export const ADMIN_UPDATE_DONOR = '/admin/donors'
export const ADMIN_DELETE_DONOR = '/admin/donors'

export const ADMIN_ADD_DONATION = '/admin/create-donation'
export const ADMIN_UPDATE_DONATION = '/admin/update-donation'
export const ADMIN_DELETE_DONATION = '/admin/delete-donation'
export const ADMIN_GET_DONATIONS_BY_DONOR = '/admin/donations-by-donor'

// other constants
export const PAGINATION_PER_PAGE = 15

// image dimensions
export const TOP_SLIDER_IMAGE_HEIGHT = 475
export const TOP_SLIDER_IMAGE_WIDTH = 1440

export const HOSPITAL_HORIZONTAL_IMAGE_HEIGHT = 475
export const HOSPITAL_HORIZONTAL_IMAGE_WIDTH = 1280

export const HOSPITAL_VERTICAL_IMAGE_HEIGHT = 400
export const HOSPITAL_VERTICAL_IMAGE_WIDTH = 350

export const DOCTOR_IMAGE_HEIGHT = 400
export const DOCTOR_IMAGE_WIDTH = 350

export const PROFILE_IMAGE_HEIGHT = 400
export const PROFILE_IMAGE_WIDTH = 350

export const NEWS_PREVIEW_IMAGE_HEIGHT = 280
export const NEWS_PREVIEW_IMAGE_WIDTH = 440

export const NEWS_POSTER_IMAGE_HEIGHT = 450
export const NEWS_POSTER_IMAGE_WIDTH = 800

export const DONOR_IMAGE_HEIGHT = 400
export const DONOR_IMAGE_WIDTH = 350

export const DEFAULT_PER_PAGE = 15
