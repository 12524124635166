import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './features/apiSlice.js'
import authReducer from './features/auth/authSlice.js'
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger.js'

const store = configureStore({
  reducer: {
    auth: authReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(apiSlice.middleware).concat(rtkQueryErrorLogger),
})

export default store
