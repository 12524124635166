import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token || JSON.parse(localStorage.getItem('admin'))?.token
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: async (args, api, extraOptions) => {
    return await baseQuery(args, api, extraOptions)
  },
  tagTypes: [],
  endpoints: (builder) => ({}),
})
