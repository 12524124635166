import React, { Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { Login } from './components/pages'

import { useValidateTokenMutation } from './redux/features/auth/authApiSlice.js'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom/dist'
import { toast } from 'react-toastify'
import { LOGIN_ROUTE } from './common/constants'
import { authenticate, logout } from './redux/features/auth/authSlice'
import { PrivateRoutes } from './components/organisms'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated)
  const [validateToken, { data: response, isSuccess }] = useValidateTokenMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const token = JSON.parse(localStorage.getItem('admin'))?.token
  useEffect(() => {
    validateToken(token)
      .unwrap()
      .catch(() => {
        dispatch(logout())
        navigate(LOGIN_ROUTE)
      })
  }, [])

  useEffect(() => {
    if (isSuccess) {
      dispatch(authenticate(response.result))
      toast.success('Welcome to the admin panel!')
    }
  }, [isSuccess])

  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route element={<PrivateRoutes isAuthenticated={isAuthenticated} />}>
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default App
