import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const PrivateRoutes = ({ isAuthenticated }) => {
  return (
    <>
      {isAuthenticated ? (
        <Outlet />
      ) : (
        <Navigate
          to={{
            pathname: '/login',
          }}
        />
      )}
    </>
  )
}

PrivateRoutes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
}

export default PrivateRoutes
