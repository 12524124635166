import { apiSlice } from '../apiSlice'

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: [],
})

export const authApiSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (payload) => ({
        url: `/admin/login`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateProfile: builder.mutation({
      query: (payload) => ({
        url: `/admin/update-profile`,
        method: 'POST',
        body: payload,
      }),
    }),
    validateToken: builder.mutation({
      query: (token) => ({
        url: '/admin/validate-token',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
})

export const { useLoginMutation, useUpdateProfileMutation, useValidateTokenMutation } = authApiSlice
