import React from 'react'

const Login = React.lazy(() => import('./login/Login'))
const Profile = React.lazy(() => import('./profile/profile'))

// hero sliders
const SliderList = React.lazy(() => import('./hero/slider-list'))
const AddSlider = React.lazy(() => import('./hero/add-slider'))
const EditSlider = React.lazy(() => import('./hero/edit-slider'))

// hospitals
const HospitalList = React.lazy(() => import('./hospitals/hospital-list'))
const AddHospital = React.lazy(() => import('./hospitals/add-hospital'))
const EditHospital = React.lazy(() => import('./hospitals/edit-hospital'))
const HospitalDetails = React.lazy(() => import('./hospitals/hospital-details'))

// doctors
const DoctorList = React.lazy(() => import('./doctors/doctor-list'))
const AddDoctor = React.lazy(() => import('./doctors/add-doctor'))
const EditDoctor = React.lazy(() => import('./doctors/edit-doctor'))

// specialties
const SpecialtyList = React.lazy(() => import('./doctors/specialties/specialty-list'))
const AddSpecialty = React.lazy(() => import('./doctors/specialties/add-specialty'))
const EditSpecialty = React.lazy(() => import('./doctors/specialties/edit-specialty'))

//news
const NewsList = React.lazy(() => import('./news/news-list'))
const AddNews = React.lazy(() => import('./news/add-news'))
const EditNews = React.lazy(() => import('./news/edit-news'))

// donor routes
const DonorList = React.lazy(() => import('./blood-bank/donor-list'))
const AddDonor = React.lazy(() => import('./blood-bank/add-donor'))
const EditDonor = React.lazy(() => import('./blood-bank/edit-donor'))
const DonorDetails = React.lazy(() => import('./blood-bank/donor-details'))

export {
  Login,
  Profile,
  SliderList,
  AddSlider,
  EditSlider,
  HospitalList,
  AddHospital,
  EditHospital,
  HospitalDetails,
  DoctorList,
  AddDoctor,
  EditDoctor,
  SpecialtyList,
  AddSpecialty,
  EditSpecialty,
  NewsList,
  AddNews,
  EditNews,
  DonorList,
  AddDonor,
  EditDonor,
  DonorDetails,
}
